import React from 'react';
import t from '../locales';

const FloatingButton = () => {
  return (
    <a href={`tel:${t('footer.contanctAndInformation.phone').replace(/\s/g, '')}`}>
      <div className="floating-wrapper">
        <label>{t('footer.callUs')}</label>
        <i className="hm-phone massive white mx-auto my-0" />
      </div>
    </a>
  );
};

export default FloatingButton;
