import React from 'react';
import logo from '../images/logo-horsemanago.png';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import List from './shared/list';
import t from '../locales';

export const socialIcon = graphql`
  fragment socialIcon on File {
    childImageSharp {
      fixed(quality: 100, width: 42, height: 42) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

const Footer = () => {
  const emailAddress = t('footer.contanctAndInformation.mail');
  const phoneString = t('footer.contanctAndInformation.phone');
  const phoneSupportString = t('footer.contanctAndInformation.phoneSupport');
  const phoneNumber = phoneString ? phoneString.replace(/\s/g, '') : '';
  const phoneSupportNumber = phoneSupportString ? phoneSupportString.replace(/\s/g, '') : '';

  const footerMenus = [
    [
      {
        key: 1,
        icon: 'hm-home teal',
        content: (
          <>
            <div>{t('footer.address.vascoAddress1')}</div>
            <div>{t('footer.address.vascoAddress2')}</div>
            <div>{t('footer.address.vascoAddress3')}</div>
          </>
        ),
      },
    ],
    [
      {
        key: 1,
        icon: 'hm-mail teal',
        content: <a href={`mailto:${emailAddress}`}>{emailAddress}</a>,
      },
      {
        key: 2,
        icon: 'hm-phone teal',
        content: (
          <>
            {t('footer.salesLabel')}: <a href={`tel:${phoneNumber}`}>{phoneString}</a>
          </>
        ),
      },
      {
        key: 3,
        icon: 'hm-phone teal',
        content: (
          <>
            {t('footer.supportLabel')}: <a href={`tel:${phoneSupportNumber}`}>{phoneSupportString}</a>
          </>
        ),
      },
    ],
  ];

  return (
    <footer className="inverted">
      <div className="container">
        <div className="border-teal border-b text-center py-6">
          <img className="logo" src={logo} alt="logo HORSEmanago" />
        </div>
        <div className="footer-nav">
          <div>
            <h4>{t('footer.address.title')}:</h4>
            <List items={footerMenus[0]} />
          </div>
          <div>
            <h4>{t('footer.contanctAndInformation.title')}:</h4>
            <List items={footerMenus[1]} />
          </div>
          <div className="social-block">
            <StaticQuery
              query={graphql`
                query {
                  facebook: file(relativePath: { eq: "facebook.png" }) {
                    ...socialIcon
                  }
                  instagram: file(relativePath: { eq: "instagram.png" }) {
                    ...socialIcon
                  }
                }
              `}
              render={data => {
                return (
                  <>
                    <a href="https://www.facebook.com/HORSEmanago/" title="Facebook HORSEmanago">
                      <Img fixed={data.facebook.childImageSharp.fixed} alt="Facebook HORSEmanago" />
                    </a>
                    <a href="https://www.instagram.com/HORSEmanago/" title="Instagram HORSEmanago">
                      <Img fixed={data.instagram.childImageSharp.fixed} alt="Instagram HORSEmanago" />
                    </a>
                  </>
                );
              }}
            />
            <div className="privacy-link">
              <a href="/cookies-policy" target="_blank">
                {t('cookies.cookiesPolicy')}
              </a>
            </div>

            <div className="privacy-link">
              <a href="/privacy-policy" target="_blank">
                {t('footer.privacyPolicy')}
              </a>
            </div>

            <div className="privacy-link">
              <a href="/terms-of-service" target="_blank">
                {t('footer.termsOfService')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
